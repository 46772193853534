import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { BellOutlined } from '@ant-design/icons';
import { DeleteOutlined, Refresh } from '@mui/icons-material';
import useNotifications from 'hooks/useNotification';
import { NotificationIcon } from './NotificationIcon';
import NotificationDescription from './NotificationDescription';
import { getNotificationColor } from './constants';
import { momentWithUserTZ } from 'utils/momentWithTz';
import NotificationListItem from './NotificationListItem';

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const {
    notifications,
    unreadCount,
    markAllAsRead,
    deleteNotification,
    isLoading,
    refetch,
  } = useNotifications();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Handle marking notifications as read when dropdown opens
  useEffect(() => {
    if (open && unreadCount > 0) {
      markAllAsRead();
    }
  }, [open, unreadCount, markAllAsRead]);

  const renderNotification = notification => (
    <NotificationListItem
      key={`${notification.uuid}-${notification.is_read}`}
      notification={notification}
      onDelete={deleteNotification}
      onNotificationClick={notification.onNotificationClick}
    />
  );

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: 'text.primary',
          bgcolor: open ? theme.palette.action.hover : 'transparent',
        }}
        aria-label="open notifications"
        ref={anchorRef}
        aria-controls={open ? 'notification-list' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={unreadCount} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 400,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifications"
                  titleSX={{
                    fontSize: '16px !important',
                    lineHeight: '24px !important',
                    fontWeight: '500 !important',
                    color: theme.palette.text.primary,
                  }}
                  contentSX={{ p: '16px 20px' }}
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <Button
                      startIcon={<Refresh />}
                      onClick={refetch}
                      sx={{ fontSize: '0.875rem', fontWeight: 500 }}
                    >
                      Refresh
                    </Button>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 1,
                        bgcolor: '#fff',
                        '&.Mui-selected': { color: 'text.primary' },
                        '& .MuiAvatar-root': { width: 36, height: 36, fontSize: '1rem' },
                        '& .MuiListItemSecondaryAction-root': {
                          mt: '6px',
                          ml: 1,
                          top: 'auto',
                          right: 'auto',
                          alignSelf: 'flex-start',
                          transform: 'none',
                          position: 'relative',
                        },
                      },
                      maxHeight: 355,
                      overflow: 'auto',
                    }}
                  >
                    {isLoading ? (
                      <ListItemButton>
                        <ListItemText primary="Loading notifications..." />
                      </ListItemButton>
                    ) : notifications.length === 0 ? (
                      <ListItemButton>
                        <ListItemText primary="No notifications" />
                      </ListItemButton>
                    ) : (
                      notifications.map(renderNotification)
                    )}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
